<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_CHANGE_PASSWORD')" type="back" :settings="false" />
    </template>

    <div class="settingsinput-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.CurrentPassword"
            name="CurrentPassword"
            :required="validationRules?.CurrentPassword?.required"
            :minlength="validationRules?.CurrentPassword?.minimumLength"
            :maxlength="validationRules?.CurrentPassword?.maximumLength"
            :label="$t.getTranslation('LBL_CURRENT_PASSWORD')"
            :info="$t.getTranslation('LBL_CURRENT_PASSWORD_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            :type="isCurrentPasswordShown ? 'text' : 'password'"
            placeholder="********"
            validate
          >
            <template #content-end>
              <f7-link tabindex="-1" class="show-password"  @click="toggleCurrentPassword">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
              </f7-link>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.NewPassword"
            name="NewPassword"
            :required="validationRules?.NewPassword?.required"
            :minlength="validationRules?.NewPassword?.minimumLength"
            :maxlength="validationRules?.NewPassword?.maximumLength"
            :label="$t.getTranslation('LBL_NEW_PASSWORD')"
            :info="$t.getTranslation('LBL_NEW_PASSWORD_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            :type="isNewPasswordShown ? 'text' : 'password'"
            placeholder="********"
            validate
          >
            <template #content-end>
              <f7-link tabindex="-1" class="show-password" @click="toggleNewPassword">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
              </f7-link>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.ConfirmPassword"
            name="ConfirmPassword"
            :required="validationRules?.ConfirmPassword?.required"
            :minlength="validationRules?.ConfirmPassword?.minimumLength"
            :maxlength="validationRules?.ConfirmPassword?.maximumLength"
            :label="$t.getTranslation('LBL_CONFIRM_PASSWORD')"
            :info="$t.getTranslation('LBL_CONFIRM_PASSWORD_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            :type="isConfirmPasswordShown ? 'text' : 'password'"
            placeholder="********"
            validate
          >
            <template #content-end>
              <f7-link tabindex="-1" class="show-password" @click="toggleConfirmPassword">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
              </f7-link>
            </template>
          </f7-list-input>
          <f7-list-item>
            <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="next">
              {{ $t.getTranslation("LBL_UPDATE") }}
            </f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, inject, computed, defineAsyncComponent } from "vue";

import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "SettingsPasswordPage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();
   
    const isCurrentPasswordShown = ref(false);
    const isNewPasswordShown = ref(false);
    const isConfirmPasswordShown = ref(false);

    const isButtonProcessing = ref(false);

    const formData = reactive({
      CurrentPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const toggleCurrentPassword = () => {
      if (isCurrentPasswordShown.value) {
        hideCurrentPassword()
      }else{
        showCurrentPassword();
      }
    }

    const toggleNewPassword = () => {
      if (isNewPasswordShown.value) {
        hideNewPassword()
      }else{
        showNewPassword();
      }
    }

     const toggleConfirmPassword = () => {
      if (isConfirmPasswordShown.value) {
        hideConfirmPassword()
      }else{
        showConfirmPassword();
      }
    }

    const showCurrentPassword = (event) => {
      isCurrentPasswordShown.value = true;
    };

    const hideCurrentPassword = (event) => {
      isCurrentPasswordShown.value = false;
    };

    const showNewPassword = (event) => {
      isNewPasswordShown.value = true;
    };

    const hideNewPassword = (event) => {
      isNewPasswordShown.value = false;
    };

    const showConfirmPassword = (event) => {
      isConfirmPasswordShown.value = true;
    };

    const hideConfirmPassword = (event) => {
      isConfirmPasswordShown.value = false;
    };

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const next = async () => {
      let isValid = validate(true);

      const isPasswordInvalid = formData.NewPassword !== formData.ConfirmPassword;
      if (isPasswordInvalid) {
        throw new Error($t.getTranslation("LBL_ERROR_PASSWORD_CONFIRM_PASSWORD_MATCH"));
      }

      if (isValid) {
        let ret = await post("/user/update/password", formData);
        if (ret) {
          helpers.createNotification({
            type: "info",
            title: $t.getTranslation("LBL_INFO"),
            message: $t.getTranslation("LBL_INFO_SUCCESS_PASSWORD_UPDATE"),
          });
          props.f7router.back({ force: true });
        }
      }
    };

    return {
      formData,
      validationRules,
      isCurrentPasswordShown,
      isNewPasswordShown,
      isConfirmPasswordShown,
      showCurrentPassword,
      hideCurrentPassword,
      showNewPassword,
      hideNewPassword,
      showConfirmPassword,
      hideConfirmPassword,
      isButtonProcessing,
      isDisableNextButton,
      next,
      toggleCurrentPassword,
      toggleNewPassword,
      toggleConfirmPassword
    };
  },
});
</script>
